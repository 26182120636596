<template>
    <section id="exporta_simple" class="section swatch-black section-text-shadow section-inner-shadow">
        <div class="videoFranja">
            <div class="videoGrid">
                <div class="videoFranja2">
                    <div class="contenedorTitulos">
                        <h2 class="headerDesarrollamos">
                            {{$t('sections.exporta_simple.header_title_1')}}
                        </h2>
                        <div class="inlinee">
                            <h1 class="ondita">~</h1>
                            <h1 class="headerExporta">EXPORTA SIMPLE</h1>
                            <h1 class="ondita">~</h1>
                        </div>
                        <h2 class="headerMinisterio">
                            {{$t('sections.exporta_simple.header_title_2')}}
                        </h2>
                        <a href="https://www.argentina.gob.ar/exportasimple" class="vermas btn btn-lg element-no-top" target="_blank">
                            {{$t('sections.exporta_simple.goto_button')}}
                        </a>
                    </div>
                </div>
            </div>
            <video class="video exporta-simple" autoplay="autoplay" loop>
                <source src="/assets/images/uploads/ExportaSimpleVideoWeb.mp4" type="video/mp4"/>
            </video>
            <!--<img src="assets/images/uploads/replace-video-xs.png" style="min-width: 100%" class="hidden-lg" alt="">-->
        </div>
    </section>
</template>

<script>
    export default {
        name: "exporta-simple-section"
    }
</script>

<style lang="scss" scoped>
	$exporta_simple_background_image: url('/assets/images/uploads/exporta-image-mobile.jpg');

    .videoFranja{
        position: relative;
        height: 380px;
        overflow: hidden;
    }

    .videoGrid{
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index: 5;
        background: url("/assets/images/grid-30.png"), rgba(0,0,0,0.2);
        object-fit: cover;
    }

    .videoFranja2{
        height: 100%;
        text-align: center;
        display: table;
        width: 100%;
        color: white;
    }

    .video{
        width: 100%;
    }

    .video.exporta-simple{
        background-image: $exporta_simple_background_image;
        background-size: 100% 100%;
    }

    .headerDesarrollamos {
        margin-bottom: 20px;
        font-size: 35px;
        font-weight: 500;
        text-shadow: 1px 1px 6px #000000;

    }

    .headerExporta {
        color:white;
        font-weight: 900;
        margin: 0 auto;
        background-color: #0075ab;
        font-style: italic;
        padding: 10px;
        font-size: 38px;
    }

	#exporta_simple {
		background-image: $exporta_simple_background_image;
		background-size: 100%;
		background-position: center;
	}

	@media (max-width: 700px) {
		#exporta_simple {
			background-size: auto 100%;
		}
	}
</style>