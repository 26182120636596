<template>
    <section id="development_guidelines_graph" class="section swatch-black section-inner-shadow">
        <div class="container">
            <div class="espacioBottom espacioFeatures row element-normal-bottom">
                <div class="col-xs-12 col-lg-5">
                    <div class="figure marginImgFeatures espacioMargin element-no-bottom image-filter-none image-filter-onhover fade-in text-center figcaption-middle">
                        <img
                                :src="$t('sections.development_guidelines.image_src')"
                                alt="Devices"
                                class="marginImgFeatures"
                        >
                    </div>
                </div>
                <div class="col-xs-12 col-lg-5 col-lg-offset-1">
                    <header class="text-left espacioMargin element-medium-bottom textoFeatures condensed">
                        <h1 class="bigger hairline bordered bordered-small">
                            {{$t('sections.development_guidelines.header.title')}}
                        </h1>
                    </header>
                    <ul class="features-list element-no-top element-no-bottom features-white">
                        <li class="element-no-top element-short-bottom">
                            <div class="features-list-icon">
                                <img src="assets/images/icons/graph-512.png" alt="Productividad">
                            </div>
                            <h3>
                                {{$t('sections.development_guidelines.items.professionalism.title')}}
                            </h3>
                            <p>
                                {{$t('sections.development_guidelines.items.professionalism.content')}}
                            </p>
                        </li>
                        <li class="element-no-top element-short-bottom">
                            <div class="features-list-icon">
                                <img src="assets/images/icons/list-512.png" alt="Agilidad">
                            </div>
                            <h3>
                                {{$t('sections.development_guidelines.items.agile.title')}}
                            </h3>
                            <p>
                                {{$t('sections.development_guidelines.items.agile.content')}}
                            </p>
                        </li>
                        <li class="element-no-top element-short-bottom">
                            <div class="features-list-icon">
                                <img src="assets/images/icons/cogs-512.png" alt="Productividad">
                            </div>
                            <h3>
                                {{$t('sections.development_guidelines.items.productive_tools.title')}}
                            </h3>
                            <p>
                                {{$t('sections.development_guidelines.items.productive_tools.content')}}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "development-guidelines-section"
    }
</script>

<style lang="scss" scoped>
    #development_guidelines_graph {
        background-color:#0075ab;
    }
</style>