<template>
    <section id="intro_header" class="section swatch-black section-text-shadow section-inner-shadow">
        <div 	class="background-media"
				style="background-image: url(assets/images/uploads/pcintro2.jpg); background-repeat: no-repeat; background-size: cover; background-attachment: fixed; background-position: 50% 50%;"
				data-start="background-position:50% 50%"
				data-70-top-bottom="background-position:50% 70%"
		>
        </div>
        <div class="background-overlay grid-overlay-70 "></div>
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <header class="element-tall-top  condensed" data-start="opacity:1" data-center="opacity:1" data-100-top-bottom="opacity:0">
                        <h1
                                class="super hairline bordered-normal"
                                v-html="$t('sections.intro_header.header.title')"
                        >

                        </h1>
                    </header>

                    <header
                            class="condensed contact-information skrollable skrollable-between"
                            data-start="opacity:1"
                            data-center="opacity:1"
                            data-100-top-bottom="opacity:0"
                            style="opacity: 1;"
                    >
                        <div class="contact-item">
                            <a
                                    :href="contactLinks.whatsapp.link + $t('sections.contact_us.whatsapp_message')"
                                    target="_blank"
                            >
                                <i class="fa-li" :class="contactLinks.whatsapp.icon_class"></i>
                                {{ contactLinks.whatsapp.text }}
                            </a>
                        </div>
                        <div class="contact-item">
                            <i class="fa-li" :class="contactLinks.email.icon_class"></i>
                            {{ contactLinks.email.text  }}
                        </div>
                    </header>

                    <a href="#contacto" class="scroll-to-id btn btn-link btn-lg element-no-top element-normal-bottom">
                        {{$t('sections.intro_header.contact_button')}}
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "intro-header-section",
        props: ['contactLinks']
    }
</script>

<style lang="scss" scoped>

	#intro_header {
		background-image: url('/assets/images/uploads/pcintro2.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: fixed;
		background-position: 50%;
	}
    header.contact-information {

    }
</style>