<template>
    <section id="clients" class="section swatch-white">
        <div id="clientes"></div>
        <header class="text-center element-tall-top element-no-bottom condensed">
            <h1 class="bigger hairline bordered bordered-normal">
                {{ $t('sections.clients.header.title') }}
            </h1>
            <p class="lead">
                {{ $t('sections.clients.header.subtitle') }}
            </p>
        </header>

        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <clients-carousel
							:carousel-items="carouselItems"
					></clients-carousel>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import ClientsCarousel from "../ClientsCarousel";
	export default {
        name: "ClientsSection",
		components: {
        	ClientsCarousel
        },
		data(){
        	return {
				carouselItems: [
					{
						imageSrc: 'assets/images/clients/fedex.png',
						attr: {
							alt: 					"FedEx",
							'data-original-title': 	"FedEx",
							'data-toggle': 			"tooltip",
						},
					},
					{
						imageSrc: 'assets/images/clients/ministerioDeProduccionBig.png',
						attr: {
							alt: 					"Ministerio de produccion",
							'data-original-title': 	"Ministerio de producción",
							'data-toggle': 			"tooltip",
						},
					},
					{
						imageSrc: 'assets/images/clients/peugeot.png',
						attr: {
							alt: 					"Peugeot",
							'data-original-title': 	"Peugeot",
							'data-toggle': 			"tooltip",
						},
					},
					{
						imageSrc: 'assets/images/clients/ipesa.png',
						attr: {
							alt: 					"Ipesa",
							'data-original-title': 	"IPESA",
							'data-toggle': 			"tooltip",
						},
					},
					{
						imageSrc: 'assets/images/clients/indaia.png',
						attr: {
							alt: 					"indaia",
							'data-original-title': 	"Indaia",
							'data-toggle': 			"tooltip",
						},
					},
					{
						imageSrc: 'assets/images/clients/goicoechea.png',
						attr: {
							alt: 					"Goicoechea hnos",
							'data-original-title': 	"Goicoechea hnos",
							'data-toggle': 			"tooltip",
						},
					},
					{
						imageSrc: 'assets/images/clients/dhl.png',
						attr: {
							alt: 					"DHL",
							'data-original-title': 	"DHL",
							'data-toggle': 			"tooltip",
						},
					},
				]
			}

		}
	}
</script>

<style lang="scss" scoped>

</style>