<template>
	<div id="clientsCarousel">
		<div class="carousel-displayer">
			<div
					class="carousel-content"
					:style="{transform: `translateX(${position}px)`}"
			>
				<template
						v-if="itemWidth"
				>
					<figure
							v-for="(carouselItem, index) in carouselItems"
							:key="index"
							class="carousel-item"
							:style="carouselItemStyle"
					>
						<img
								:src="carouselItem.imageSrc"
								:alt="carouselItem.attr.alt"
								:data-original-title="carouselItem.attr['data-original-title']"
								:data-toggle="carouselItem.attr['data-toggle']"
								draggable="false"
						>
					</figure>
				</template>
			</div>
		</div>
		<div
				v-if="pages > 0"
				class="carousel-pagination"
		>
			<ol>
				<li
						v-for="page in pages"
						:key="page"
				>
					<a
							:class="page === activePage ? 'active' : ''"
							@click="userChangePage(page)"
					> </a>
				</li>
			</ol>
		</div>
	</div>
</template>

<script>
	let intervalId = null;

	export default {
		name: "ClientsCarousel",
		props:{
			carouselItems: {
				type: Array,
				required: true,
			}
		},
		data(){
			return {
				position: 0,
				itemWidth: null,
				itemsDisplayedAmount: 0,
				pages: 1,
				activePage: 1
			}
		},
		computed: {
			itemsCount(){
				return this.carouselItems.length;
			},
			displayerWidth(){
				return this.itemWidth * this.itemsDisplayedAmount;
			},
			contentWidth(){
				return this.itemsCount * this.itemWidth;
			},
			carouselItemStyle(){
				return {
					width: `${this.itemWidth}px`
				};
			},
			maxPosition(){
				return -(this.contentWidth) + this.displayerWidth;
			},
		},
		watch: {
			activePage(){
				this.calculatePagePosition();
			},
			itemsDisplayedAmount(){
				this.pages = Math.ceil(this.itemsCount / this.itemsDisplayedAmount);
			}

		},
		mounted(){
			this.calculateItemProperties();

			window.addEventListener('resize', _ => {
				this.calculateItemProperties();
				this.calculatePagePosition();
			});

			this.setIntervalAutomaticMovement();
		},
		methods: {
			calculatePagePosition(){
				if (this.activePage > this.pages) {
					this.activePage = this.pages;
					return;
				}

				let newPosition = -(this.displayerWidth * (this.activePage - 1));

				if (this.maxPosition > newPosition) {
					newPosition = this.maxPosition;
				}

				this.position = newPosition;
			},
			calculateItemProperties(){
				let displayerWidth 			= this.$el.querySelector('.carousel-displayer').offsetWidth;

				this.itemsDisplayedAmount 	= Math.round(displayerWidth / 185);
				this.itemWidth				= displayerWidth / this.itemsDisplayedAmount;
			},
			setIntervalAutomaticMovement(movementTimeout = 10000){
				if (intervalId) {
					clearInterval(intervalId);
					intervalId = null;
				}

				intervalId = setInterval(_ => {
					this.changePage(this.activePage + 1);
				}, movementTimeout);
			},
			changePage(page){
				this.activePage = page > this.pages
					? 1
					: page;
			},
			userChangePage(page){
				if (intervalId) {
					clearInterval(intervalId);
					intervalId = null;
				}

				setTimeout(_ => this.setIntervalAutomaticMovement(), 60000);

				this.changePage(page);
			},
		},
	}
</script>

<style lang="scss" scoped>
	#clientsCarousel {
		display: flex;
		flex-flow: column nowrap;
		margin: 2rem 0 6rem;

		.carousel-displayer {
			overflow-x: hidden;

			.carousel-content {
				display: flex;
				flex-flow: row nowrap;

				padding-top: 4rem;

				transition: transform 800ms;

				figure.carousel-item {
					flex: 0 0 auto;
					position: relative;
					margin: 0;

					img {
						width: 100%;
					}
				}
			}
		}

		.carousel-pagination {
			margin-top: 2rem;

			& > ol {
				padding: 0;
				margin: 0;
				list-style-type: none;

				display: flex;
				flex-flow: row wrap;
				justify-content: center;

				li {
					cursor: pointer;

					a {
						display: inline-block;
						width: 20px;
						height: 20px;
						border-radius: 50%;
						transition: background .5s,box-shadow .3s;
						margin: 0 8px;

						background: white;
						box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);

						&.active {
							background: rgba(0, 0, 0, 0.2);
						}
					}
				}
			}
		}
	}
</style>