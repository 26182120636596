<template>
    <section id="about_us" class="section">
        <div class="container">
            <div class="row">
                <div id="quienes"></div>
                <div class="col-md-12 text-center">
                    <header class="element-tall-top element-medium-bottom not-condensed">
                        <h1 class="bigger hairline bordered bordered-normal">
                            {{$t('sections.about_us.header.title')}}
                        </h1>
                    </header>
                    <div class="col-md-6">
                        <p
                                class="lead text-justify element-no-top element-short-bottom animation"
                                v-html="$t('sections.about_us.paragraphs.left')"
                        >
                        </p>
                    </div>
                    <div class="col-md-6">
                        <p
                            class="lead text-justify element-no-top element-normal-bottom"
                            v-html="$t('sections.about_us.paragraphs.right')"
                        >
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "about-us-section"
    }
</script>

<style lang="scss" scoped>
    #about_us{
        background: #e9e9e9;
        color: #4c4c4c;

        .bordered:after{
            background-color: #1c1c1c;
        }
    }
</style>