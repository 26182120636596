<template>
    <footer id="footer" role="contentinfo">
        <section class="fondoGrisOscuro section swatch-black">
            <div class="container">
                <div class="espaciadoFooter row element-normal-top element-normal-bottom">

                    <div class="col-md-4">
                        <div id="site_map" class="sidebar-widget widget_categories">
                            <h3 class="sidebar-header">
                                {{$t('footer.menu_title')}}
                            </h3>
                            <ul>
                                <li class="cat-item cat-item-65">
                                    <a href="#">
                                        {{$t('header.menu.home.title')}}
                                    </a>
                                </li>
                                <li class="cat-item cat-item-62">
                                    <a href="#clientes" :title="$t('header.menu.clients.description')">
                                        {{$t('header.menu.clients.title')}}
                                    </a>
                                </li>
                                <li class="cat-item cat-item-47">
                                    <a href="#quienes" :title="$t('header.menu.about_us.description')">
                                        {{$t('header.menu.about_us.title')}}
                                    </a>
                                </li>
                                <li class="cat-item cat-item-54">
                                    <a href="#contacto" :title="$t('header.menu.contact.description')">
                                        {{$t('header.menu.contact.title')}}
                                    </a>
                                </li>

                            </ul>
                        </div>

                    </div>
                    <div class="col-md-4">
                        <div class="sidebar-widget widget_tag_cloud">
                            <h3 class="sidebar-header">
                                {{$t('footer.contact_title')}}
                            </h3>
                            <ul id="contact_list_global">
                                <li class="no-padding col-xs-12">
                                    <i class="iconSeparation fa fa-envelope"></i>
                                    info@zedplan.com
                                </li>
                                <li
                                        v-for="(country, country_code) in availableCountries"
                                        class="no-padding col-xs-12"
                                        :class="mainCountry === country_code ? 'selectedCountry' : ''"
                                >
                                    <i class="iconSeparation fa fa-phone"></i>
                                    <b>{{country.country_name}}</b>
                                    {{country.contact_links.phone.text}}
                                </li>
                                <li class="no-padding col-xs-12">
                                    <p class="copyright">© 2019 ZEDPLAN ALL RIGHTS RESERVED</p>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div id="recent-posts-4" class="sidebar-widget  widget_recent_entries ">
                            <img src="assets/images/Isologotipopng.png" alt="Zedplan Logo" >
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </footer>
</template>

<script>
    export default {
        name: "footer-layout",
        props: ['availableCountries', 'mainCountry']
    }
</script>

<style lang="scss" scoped>
	#site_map {
		ul {
			a {
				&:hover {
					color: #82c9ed;
				}
			}
		}
	}

    ul#contact_list_global {
        display: flex;
        flex-direction: column;

        li {
            order: 3;
        }

        li:first-of-type{
            order: 1;
        }

        li.selectedCountry {
            order: 2;
        }
    }

    .iconSeparation {
        margin-right: .5em;
    }
</style>