<template>
    <main id="content">
            <intro-header-section
                    :contactLinks="contactLinks"
            ></intro-header-section>

            <services-section></services-section>

            <exporta-simple-section></exporta-simple-section>

            <development-guidelines-section></development-guidelines-section>

            <clients-section></clients-section>

            <slogan-section></slogan-section>

            <about-us-section></about-us-section>

            <our-location-section></our-location-section>

            <contact-us-section
                    :contactLinks="contactLinks"
            ></contact-us-section>
    </main>
</template>

<script>
    import IntroHeaderSection           from "../sections/IntroHeaderSection";
    import AboutUsSection               from "../sections/AboutUsSection";
    import SloganSection                from "../sections/SloganSection";
    import ClientsSection               from "../sections/ClientsSection";
    import DevelopmentGuidelinesSection from "../sections/DevelopmentGuidelinesSection";
    import ExportaSimpleSection         from "../sections/ExportaSimpleSection";
    import ServicesSection              from "../sections/ServicesSection";
    import OurLocationSection           from "../sections/OurLocationSection";
    import ContactUsSection             from "../sections/ContactUsSection";

    export default {
        name: "main-layout",
        components: {
            IntroHeaderSection,
            ServicesSection,
            ExportaSimpleSection,
            DevelopmentGuidelinesSection,
            ClientsSection,
            SloganSection,
            AboutUsSection,
            OurLocationSection,
            ContactUsSection,
        },
        props: ['contactLinks']
    }
</script>

<style lang="scss" scoped>

</style>