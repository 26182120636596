<template>
    <div
            id="masthead"
            role="banner"
            class="navbar navbar-static-top swatch-white navbar-sticky"
    >
		
        <div class="container">
            <div class="navbar-header">
                <button type="button" class="navbar-toggle collapsed">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>

                <a
                        class="social-link whatsapp-link mobile"
                        target="_blank"
                        :href="whatsappLinkWithMsg"
                >
                    <i class="fab fa-whatsapp"></i>
                </a>

                <a
						href="/"
						class="navbar-brand"
				>
                    <img src="../../assets/images/LogoZedplanChico.png" alt="ZedPlan Logo">
                    <span class="zedTitle">
                        ZED</span><span class="planTitle">PLAN
                    </span>
                </a>
            </div>

            <nav
					class="collapse navbar-collapse main-navbar"
					role="navigation"
			>
                <div class="menu-sidebar pull-right">
                </div>

                <a
						class="social-link whatsapp-link"
						:href="contactLinks.whatsapp.link"
						target="_blank"
				>
                    <i class="fab fa-whatsapp"></i>
                </a>


                <ul class="nav navbar-nav navbar-right">
                    <li>
                        <a href="#">
                            {{ $t('header.menu.home.title') }}
                        </a>
                    </li>
                    <li>
                        <a href="#clientes">
                            {{ $t('header.menu.clients.title') }}
                        </a>
                    </li>
                    <li>
                        <a href="#quienes">
                            {{ $t('header.menu.about_us.title') }}
                        </a>
                    </li>
                    <li>
                        <a href="#contacto">
                            {{ $t('header.menu.contact.title') }}
                        </a>
                    </li>
                    <li>
                        <language-selector
                                :current-language="currentLanguage"
                        ></language-selector>
                    </li>
                </ul>
            </nav>

        </div>
    </div>
</template>

<script>
    import LanguageSelector from "../LanguageSelector";

    export default {
		name: "HeaderLayout",
		components: {
            LanguageSelector
        },
		props: {
			contactLinks: {
				type: Object,
				required: true,
			},
			currentLanguage: {
				type: String,
				required: true,
			}
		},
		computed: {
			whatsappLinkWithMsg(){
				return `${this.contactLinks.whatsapp.link}${this.$t('sections.contact_us.whatsapp_message')}`;
			}
		}

		// props: ['contactLinks', 'currentLanguage']
    }
</script>

<style lang="scss" scoped>
	/** Barra de contactos**/

	.barraContacto{
		background: #0075ab; /* Old browsers */
		background: -moz-linear-gradient(left, #00aab4 0%, #0075ab 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, #00aab4 0%,#0075ab 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, #00aab4 0%,#0075ab 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0075ab', endColorstr='#00aab4',GradientType=1 ); /* IE6-9 */
		margin-bottom: 0;

		a i {
			color: white;
		}

		.barraContent {
			text-align: center;

			a {
				color: white;
				padding-left: 3em;
			}
		}

		.barraContentMobile {
			display: none;

			 a,
			.section-description {
				color: white;
				padding-left: 1em;
			}

			.section-description {
				font-weight: 600;
			}
		}
	}


    nav{
        ul.nav.navbar-nav {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
    ul.nav.navbar-nav {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    /** Social Link **/
    .navbar > .container .social-link {
        transition: padding 200ms ease;
        padding: 32px 15px;
        margin-left: 27px;
        margin-bottom: -20px;
        float: right;
        position: relative;
        top: 0;
    }

    .navbar > .container .social-link.whatsapp-link {
        background-color: #11a501;
    }

    .navbar > .container .social-link.whatsapp-link:hover {
        background-color: #10c100;
    }

    .navbar.navbar-scrolled > .container .social-link{
        padding: 21px 15px;
    }

    .navbar.navbar-scrolled > .container .social-link:after{
        border-width: 5px 10px 0 0;
    }

    .navbar > .container .social-link i {
        color: #FFF !important;
        font-size: 30px;
    }

    .navbar > .container .social-link:after{
        content: "";
        font-size: 0.5em;
        position: absolute;
        border-style: solid;
        bottom: 0;
        right: -10px;
        border-width: 7.5px 10px 0 0;
        border-color:#05a500 transparent transparent transparent;
        z-index: -50;
    }

    .navbar > .container .social-link:hover:after{
        border-color: #07ad00 transparent transparent transparent;
    }

    /** Social Link [mobile] **/
    .navbar > .container .social-link.mobile{
        display: inline-block;
        transition:
                background-color 200ms ease-in,
                padding 300ms ease;
        margin-right: 15px;
    }

    .navbar > .container > .navbar-header > .social-link.mobile:after{
        transition: opacity 500ms ease;
    }

    .navbar.navbar-collapse-in > .container > .navbar-header > .social-link.mobile{
        background-color: rgba(17, 165, 1, .75);
        padding: 19px 20px;
    }

    .navbar.navbar-collapse-in > .container > .navbar-header > .social-link.mobile:after{
        opacity: 0;
        border: 0;
    }

    .navbar > .container > .navbar-header > .social-link.mobile{
        display: none;
    }

    @media (max-width: 992px) {
        .navbar ul.nav.navbar-nav {
            flex-direction: column;
            align-items: stretch;
        }

        .navbar > .container .social-link.whatsapp-link:not(.mobile){
            display: none;
        }
        .navbar > .container .social-link.whatsapp-link.mobile{
            display: inline-block;
        }
    }

    @media (max-width: 350px) {
        .zedTitle,
        .planTitle {
            display: none;
        }
    }

	@media (max-width: 600px) {
		figure.barraContacto {
			& > .barraContent {
				display: none;
			}

			& > .barraContentMobile {
				display: flex;
				flex-flow: row;
			}

			& > .barraContentMobile > [class^=column-] {
				flex: 1 0 50%;
				display: flex;
				flex-flow: column;

				padding: 1rem 0;
			}

			& > .barraContentMobile > [class^=column-] > *:first-child {
				transition: transform  900ms;
			}

		}

		.navbar-scrolled figure.barraContacto > .barraContentMobile > [class^=column-] > *:first-child {
			animation: move-up 400ms;
			position: absolute;
			transform: translateY(-200%);
		}

		@keyframes move-up {
			0% {
				transform: translateY(0);
				position: relative;
				opacity: 1;
			}

			100% {
				transform: translateY(-200%);
				opacity: 0;
			}

		}
	}
</style>