<template>
    <div
            class="language"
            @mouseleave="toggleDialogOpen"
    >
        <div class="current">
            <div
                    v-if="!loadingLanguages"
                    @click="toggleDialogOpen"
                    @mouseover="toggleDialogOpen"
            >
                <div
                    class="selected"
                >
                    {{selectedLanguage.name}}
                    <i class="fa fa-caret-down"></i>
                </div>
            </div>
        </div>

        <div class="list" :class="{open: openDialog}">
            <div>
                <ul>
                    <li
                            class="item"
                            v-for="language in languages"
                            :key="language.key"
                            @click="changeLocale(language)"
                    >
                        <span>
                            {{language.name}}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import i18n     from "../i18n";
    import EventBus from "../eventBus";

    export default {
        name: "language-selector",
        data(){
            return {
                languages: [],
                selectedLanguage: null,
                loadingLanguages: true,
                openDialog: false,
            }
        },
        props: ['currentLanguage'],
        created: function(){
            this.languages        = require('../config/languages');

            this.selectedLanguage = this.languages.find(lang => lang.key === this.currentLanguage);
            this.loadingLanguages = false;

            EventBus.$on('languageChanged', language => {
                this.selectedLanguage   = language;
            });
        },
        methods: {
            changeLocale(language){
                this.openDialog         = false;
                EventBus.$emit('languageChanged', language);
            },
            toggleDialogOpen(e){
                const isMobile = window.matchMedia('(max-width: 992px)').matches;

                switch(e.type) {
                    case 'click':
                        this.openDialog = isMobile
                            ? !this.openDialog
                            : true;
                        break;

                    case 'mouseover':
                        if (!isMobile) {
                            this.openDialog = true;
                        }

                        break;

                    case 'mouseleave':
                        this.openDialog = false;
                        break;

                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .language{
        position: relative;
        height: 100%;
        padding: 10px 0;
        transition: color 500ms ease;
        color: rgba(76, 76, 76, .5);

        &:hover {

        }

        .current {
            .selected{
                cursor: pointer;
                padding: 0 15px;
                text-align: center;
                transition: color 500ms ease;

                /*&:hover {*/
                    /*color: rgba(130, 201, 237, .5);*/
                /*}*/
            }
        }

        .list {
            display: none;
            position: absolute;
            left: 0;
            width: 100%;
            height: auto;
            background-color: rgb(255, 255, 255);
            z-index: 20;

            &.open{
                display: block;
            }

            ul{
                list-style: none;
                padding: 0 0;
                margin: 0 0;

                .item {
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    text-align: left;
                    padding: 5px 10px;
                    line-height: 36px;
                    cursor: pointer;
                    transition: color 500ms ease, background-color 500ms ease;

                    &:hover {
                        /*background-color: rgba(128, 128, 128, .1);*/
                        color: rgba(76, 76, 76, .7);
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    @media (max-width: 992px) {
        .language > .list {
            position: relative;
            background-color: inherit;
        }
    }
</style>