<template>
  <div id="app" >
      <div id="appWrapper" :class="isLoading ? 'loading' : ''">
          <header-layout
                  :contactLinks="currentCountry.contact_links"
                  :currentLanguage="currentLanguage"

          ></header-layout>
          <main-layout
                  :contactLinks="currentCountry.contact_links"
          ></main-layout>
          <footer-layout
                  :availableCountries="availableCountries"
                  :mainCountry="mainCountry"
          ></footer-layout>
      </div>
      <spinner
              :isActive="isLoading"
      ></spinner>
  </div>
</template>

<script>
    import FooterLayout     from "./components/layout/FooterLayout";
    import HeaderLayout     from "./components/layout/HeaderLayout";
    import MainLayout       from "./components/layout/MainLayout";
    import i18n             from "./i18n";
    import Spinner          from "./components/Spinner";
    import EventBus         from "./eventBus";

    export default {
        name: 'app',
        data: function(){
            return {
                isLoading: false,
                mainCountry: 'AR',
                availableCountries: {},
                currentLanguage: this.$i18n.locale,
            }
        },
        components: {
            Spinner,
            HeaderLayout,
            MainLayout,
            FooterLayout
        },
        created: function(){
            this.availableCountries = require('./config/available_countries');

            // const currentUrl  = new URL(document.URL);
            // for(let key in this.availableCountries) {
            //     if (!this.availableCountries.hasOwnProperty(key)) {
            //         continue;
            //     }
            //
            //     if(this.availableCountries[key].url_hostname === currentUrl.hostname) {
            //         this.mainCountry  = key;
            //         break;
            //     }
            // }
            this.mainCountry = 'AR';

            let routeLocale = null;

            if (this.$route.params.hasOwnProperty('locale')) {
                routeLocale = Object.keys(this.$i18n.messages).includes(this.$route.params.locale)
                    ? this.$route.params.locale
                    : this.$i18n.locale;
            }

            if (routeLocale) {
				this.currentLanguage = routeLocale;
			}

            this.isLoading = false;

            EventBus.$on('languageChanged', language => {
                this.changedLanguage(language);
            });
        },
        methods: {
            changedLanguage: function(lang){
                this.isLoading = true;
                this.$router.push('/'+lang.key);
                setTimeout(_ => {
                    this.currentLanguage = lang.key;
                    this.isLoading = false;
                }, 500);
            }
        },
        computed: {
            currentCountry: function(){
                return this.availableCountries[this.mainCountry];
            }
        },
        watch: {
            // mainCountry: function () {
            //     this.currentLanguage = this.availableCountries[this.mainCountry].default_lang;
            // },
            currentLanguage: function () {
				let currentLanguage = this.currentLanguage.toLocaleLowerCase();

				if  (i18n.locale !== currentLanguage) {
					i18n.locale = this.currentLanguage.toLocaleLowerCase();
				}
            }
        },
    }
</script>


<style lang="scss">
	/** Essential External Libraries **/
	/*@import "~bootstrap-sass/assets/stylesheets/bootstrap-sprockets";*/
	/*@import '~bootstrap-sass/assets/stylesheets/bootstrap';*/

	@import './assets/styles/bootstrap.css';
	@import './assets/styles/theme.min.css';
	@import './assets/styles/color-defaults.css';
	@import './assets/styles/swatch-white.css';
	/** Essential External Libraries **/

	@import './assets/styles/swatch';

	#appWrapper {
		background-color: black;
		transition: filter 2s ease;

		&.loading {
			filter: blur(10px);
		}
	}

	.zedplan-blueBG2{
		background: #0089af;
		color: #fff;
	}

	.zedplan-blueCOL2{
		background-color: #0089af !important;
		color: white;
	}

	.zedplan-blueCOL2:hover{
		color: white;
		background-color: #00aab5 !important;
	}

	.espacioMargin{
		margin-top: 60px;
		padding-top:75px;
	}

	.espacioBottom{
		margin-bottom: 15px!important;
	}

	.textoFeatures{
		margin-left:0;
	}

	.no-padding {
		padding: 0;
	}

	.fondoGrisOscuro {
		background-color: #313131;
	}

	.espaciadoFooter {
		margin-bottom: 30px!important;
		margin-top: 30px!important;
		margin-left:1px!important;
	}

	.zedTitle {
		color:#0089af;
		font-family: 'Exo 2', sans-serif;
	}

	.planTitle {
		color:grey;
		font-family: 'Exo 2', sans-serif;
	}

	.botonMailMargin {
		margin-bottom: 15px;
	}

	.iconColor {
		color: #0089af!important;
	}

	.flex-slider-bottom {
		margin-bottom: 50px !important;
	}

	.espaciadoImgFeatures {
		padding-top: 75px;
	}

	.espacioFeatures {
		margin-top: -60px;
	}

	.marginImgFeatures {
		margin-top: 70px;
	}

	.element-tall-top-text {
		margin-top: 55px!important;
	}

	.mailOk {
		border-radius: 10px;
		background: #90bc5d;
		padding: 8px;
	}

	.colorRectangulo {
		background:crimson;
		border-radius: 10px;
		padding: 8px;
	}

	.titleNoColapsing {
		min-height:36px;
	}

	.tamanoIconoMail {
		height: 35px;
		width: 35px;
	}

	.success-message .mailOk p {
		padding-top: 13px;
	}

	.swatch-white p > span.mensajeMail {
		padding-top: 30px;
		padding-left: 6px;
		font-size: 17px;
		color: white;
		font-weight: bold;
		font-family: 'Source Sans Pro', serif;
	}

	.contenedorTitulos {
		display:table-cell;
		vertical-align:middle;
		color:white;
		font-family: 'Source Sans Pro', serif;
	}

	.ondita {
		font-size: 40px;
	}

	.inlinee > * {
		display: inline-block;
	}

	.headerMinisterio {
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 35px;
		font-weight: 500;
		text-shadow: 1px 1px 6px #000000;
	}

	.vermas {
		background: 0 0;
		text-decoration: none;
		box-shadow: 0 0 0 3px #f5f5f5 inset;
		font-weight: 600;
	}

	.vermas:hover {
		background-color: #f5f5f5;
		color: #3c3c3c;
		box-shadow: 0 0 0 1px #f5f5f5;
	}

	/** fonts.css **/
	body {
		font-family: 'Source Sans Pro', sans-serif;
		font-weight: 400;
	}
	body input, body textarea, body .btn, body button {
		font-family: 'Source Sans Pro', sans-serif;
	}

	blockquote p {
		font-weight: 300;
	}

	.light {
		font-weight: 300 !important;
	}

	.hairline {
		font-weight: 200 !important;
	}
	.hairline strong {
		font-weight: 400;
	}

	h1, h2, h3, h4, h5, h6 {
		font-family: 'Source Sans Pro', sans-serif;
		font-weight: 600;
	}

	.lead {
		font-weight: 300;
	}
	.lead strong {
		font-weight: 600;
	}
	/** fonts.css **/


	/** FIXES DEL THEME **/

	.grid-overlay-30 {
		background-image: url('/assets/images/grid-30.png');
	}

	/** FIXES DEL THEME **/


	@media(max-width: 700px){
		.videoFranja{
			height: 300px;
		}

		.video{
			width: 110%;
			margin-left: -5%;
		}
	}

	@media(max-width: 500px){
		.video{
			width: 130%;
			margin-left: -15%;
		}

		.videoFranja .ondita{
			display:none;
		}
	}

	@media(max-width: 415px){
		.video{
			width: 300%;
			margin-left: -100%;
		}

		.videoFranja{
			height: 400px;
		}

		.headerExporta, .headerMinisterio{
			line-height: 1.1em;
		}
	}
</style>
