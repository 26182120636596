<template>
    <section id="contact_us" class="section swatch-white">
        <div class="container">
            <div class="row">
                <div id="contacto"></div>
                <div class="col-md-12 text-center">
                    <header class="text-center element-normal-top element-medium-bottom not-condensed">
                        <h1 class="super hairline bordered bordered-normal">
                            {{$t('sections.contact_us.header.title')}}
                        </h1>
                        <p class="lead">
                            {{$t('sections.contact_us.header.subtitle')}}
                        </p>
                    </header>
                    <ul
                            class="text-center fa-ul lead element-no-top element-medium-bottom"
                            id="contact_list"
                    >
                        <li>
                            <i class="iconColor fa-li" :class="contactLinks.address.icon_class" style="color: rgb(130, 201, 235);"></i>
                            <a
									:href="contactLinks.address.link"
									target="_blank"
							>
                                {{contactLinks.address.text}}
                            </a>
                        </li>
                        <li>
                            <i class="iconColor fa-li" :class="contactLinks.phone.icon_class" style="color: rgb(130, 201, 235);"></i>
                            <a :href="contactLinks.phone.link">
                                {{contactLinks.phone.text}}
                            </a>
                        </li>
                        <li>
                            <i class="iconColor fa-li" :class="contactLinks.whatsapp.icon_class" style="color: rgb(130, 201, 235);"></i>
                            <a
                                    :href="contactLinks.whatsapp.link + $t('sections.contact_us.whatsapp_message')"
                                    target="_blank"
                            >
                                {{contactLinks.whatsapp.text}}
                            </a>
                        </li>
                        <li>
                            <i  class="iconColor fa-li" :class="contactLinks.email.icon_class" style="color: rgb(130, 201, 235);"></i>
                            <a :href="contactLinks.email.link">
                                {{contactLinks.email.text}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-md-offset-3 text-left small-screen-center element-normal-bottom">
                    <form id="contactForm" class="contact-form">
                        <div class="iconColor form-group form-icon-group">
                            <input
                                    class="form-control"
                                    id="name"
                                    name="name"
                                    type="text"
                                    :placeholder="$t('sections.contact_us.form.name_placeholder')"
                                    required
                            />
                            <i class="iconColor fa fa-user"></i>
                        </div>
                        <div class="iconColor form-group form-icon-group">
                            <input
                                    class="form-control"
                                    id="email"
                                    name="email"
                                    :placeholder="$t('sections.contact_us.form.email_placeholder')"
                                    type="email"
                                    required
                            >
                            <i class="iconColor fa fa-envelope"></i>
                        </div>
                        <div class="iconColor form-group form-icon-group">
                            <input
                                    class="form-control"
                                    id="phone"
                                    name="phone"
                                    :placeholder="$t('sections.contact_us.form.phone_placeholder')"
                                    type="text"
                                    required
                            >
                            <i class="iconColor fa fa-phone"></i>
                        </div>
                        <div class="form-group form-icon-group">
                            <textarea
                                    class="form-control titleNoColapse"
                                    id="message"
                                    name="message"
                                    :placeholder="$t('sections.contact_us.form.message_placeholder')"
                                    rows="10"
                                    required
                            ></textarea>
                            <i class="iconColor fa fa-pencil"></i>
                        </div>
                        <div class="text-center">
                            <button type="submit" class="zedplan-blueCOL2 botonMailMargin btn btn-primary">
                                {{$t('sections.contact_us.form.submit_button')}}
                            </button>
                        </div>
                    </form>
                    <div class="error-message hidden">
                        <div class="col-xs-12 colorRectangulo mailOk">
                            <p>
                                <img class="tamanoIconoMail" src="assets/images/icons/error.png" alt="Error" title="Error">
                                <span class="mensajeMail">
                                    {{$t('sections.contact_us.form.status_messages.error_message')}}
                                </span>
                            </p>
                        </div>
                    </div>

                    <div class="success-message hidden">
                        <div class="col-xs-12 mailOk">
                            <p>
                                <img
                                    class="tamanoIconoMail"
                                     src="assets/images/icons/palomita.png"
                                     alt="Mensaje exitoso"
                                     title="Mensaje exitoso"
                                >
                                <span class="mensajeMail">
                                    {{$t('sections.contact_us.form.status_messages.success_message')}}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "ContactUsSection",
		props: {
			contactLinks: {
				type: Object,
				required: true,
			},
		}
    }
</script>

<style lang="scss" scoped>
    ul#contact_list {
        display: inline-block;

        li{
            display: flex;
            flex-direction: row;
            a{
                flex: 1 0 0;
                align-self: flex-end;
            }
        }
    }
	form#contactForm {
		padding: 2rem;
		position: relative;

		&.loading:before {
			content: "Enviando formulario...";
			position: absolute;
			z-index: 1;

			width: 100%;
			height: 100%;

			text-align: center;
			padding: 45% 0;

			top: 0;
			right: 0;
			background: linear-gradient(rgba(158, 158, 158, .7), transparent);

			animation: blink 1200ms ease-out infinite;
		}
	}

	@keyframes blink {
		0% {
			opacity: .9;
		}

		50% {
			opacity: .6;
		}

		100% {
			opacity: .9;
		}
	}
</style>