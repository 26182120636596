<template>
    <div id="our_location">
        <section id="location" class="section swatch-white">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div id="mapa"></div>
                    <header class="text-center element-normal-top element-medium-bottom not-condensed">
                        <h1 class="super hairline bordered bordered-normal">
                            {{$t('sections.our_location.header.title')}}
                        </h1>
                        <p class="lead">
                            {{$t('sections.our_location.header.subtitle')}}
                        </p>
                    </header>
                </div>
            </div>
        </div>
    </section>

        <section id="location_map" class="section swatch-white">
        <div class="container-fullwidth">
            <div class="row">
                <div class="col-md-12">
					<a href="https://goo.gl/maps/w3K3ubssdoB2" target="_blank" id="gmaps_link">
                    <div
							class="google-map"
							id="map"
							style="height:400px;"
					>
					</div>
					</a>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>

<script>
    export default {
        name: "our-location-section"
    }
</script>

<style lang="scss" scoped>
	a#gmaps_link {
		&:hover {
			.google-map#map {
				filter: saturate(150%);
			}
		}
	}

	.google-map#map {
		transition: filter 300ms ease-out;
		background: rgba(200, 200, 200, .5) url("/assets/images/map.png") center no-repeat;
	}
</style>