<template>
    <section id="services" class="section swatch-white">
        <div class="container">
            <div class="row">
                <div class="col-md-12 ">
                    <header class="text-center element-tall-top element-no-bottom condensed">
                        <h1 class="bigger hairline bordered bordered-normal">

                            {{$t('sections.services.header.title')}}

                        </h1>
                        <p class="lead">
                            {{$t('sections.services.header.subtitle')}}
                        </p>
                    </header>
                    <div class="row ">
                        <div class="col-md-3 ">
                            <div class="element-medium-top element-medium-bottom text-center">
                                <div class="box box-round box-normal element-medium-top element-short-bottom">
                                    <div class="box-dummy"></div>
                                    <a class="box-inner grid-overlay-10" style="background-color:#0089af;">
                                        <img src="assets/images/icons/iphone-512w.png"/>
                                    </a>
                                </div>
                                <h3 class="normal bold bordered bordered-small ">

                                    {{$t('sections.services.items.moblie.title')}}

                                </h3>
                                <p>
                                    {{$t('sections.services.items.moblie.content')}}
                                </p>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <div class="element-medium-top element-medium-bottom text-center">
                                <div class="box box-round box-normal">
                                    <div class="box-dummy"></div>
                                    <a class="box-inner grid-overlay-10" style="background-color:#0089af;">
                                        <img src="assets/images/icons/imac-512w.png"/>
                                    </a>
                                </div>
                                <h3 class="normal bold bordered bordered-small ">

                                    {{$t('sections.services.items.web.title')}}

                                </h3>
                                <p class="">
                                    {{$t('sections.services.items.web.content')}}
                                </p>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <div class="element-medium-top element-medium-bottom text-center">
                                <div class="box box-round box-normal">
                                    <div class="box-dummy"></div>
                                    <a class="box-inner grid-overlay-10" style="background-color:#0089af;">
                                        <img src="assets/images/icons/cloud-512w.png"/>
                                    </a>
                                </div>
                                <h3 class="normal bold bordered bordered-small">

                                    {{$t('sections.services.items.saas_and_paas.title')}}

                                </h3>
                                <p>
                                    {{$t('sections.services.items.saas_and_paas.content')}}
                                </p>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <div class="element-medium-top element-medium-bottom text-center">
                                <div class="box box-round box-normal">
                                    <div class="box-dummy"></div>
                                    <a class="box-inner grid-overlay-10" style="background-color:#0089af;">
                                        <img src="assets/images/icons/users-512w.png"/>
                                    </a>
                                </div>
                                <h3 class="normal bold bordered bordered-small ">

                                    {{$t('sections.services.items.business.title')}}

                                </h3>
                                <p>
                                    {{$t('sections.services.items.business.content')}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "services-section"
    }
</script>

<style scoped>

</style>