import Vue          from 'vue';
import App          from './App.vue';
import i18n         from './i18n';
import router       from './router';
import * as Sentry  from '@sentry/browser';


Vue.config.productionTip = false;

if (process.env.hasOwnProperty('VUE_APP_SENTRY_DSN') && process.env.NODE_ENV === 'production'){
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [new Sentry.Integrations.Vue({
            Vue,
            attachProps: true
        })]
    })
}

new Vue({
  i18n,
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app');
