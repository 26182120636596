<template>
    <section id="slogan" class="section swatch-black section-text-shadow section-inner-shadow">
        <div
            class="background-media"
            data-start="background-position:50% 0px"
            data-70-top-bottom="background-position:50% -90px"
        >
        </div>
        <div class="background-overlay grid-overlay-30 " style="background-color: rgba(0,0,0,0.4);"></div>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <header class="text-center element-tall-top-text element-tall-bottom not-condensed">
                        <h1
                            class="super hairline bordered-normal"
                            v-html="$t('sections.slogan.header.title')"
                        >
                        </h1>
                    </header>
                    <div
                        class="figure element-short-top element-no-bottom image-filter-none image-filter-onhover text-center figcaption-middle"
                    >
                        <span class="figure-image">
                            <img src="assets/images/uploads/3pcs.png" alt="Zedplan">
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "slogan-section"
    }
</script>

<style lang="scss" scoped>

    .background-media {
        background: url('../../assets/images/uploads/dusenador.jpg') no-repeat fixed 50% 0;
        background-size: cover;
    }

	@media (max-width: 700px) {
		#slogan {
			background: url('../../assets/images/uploads/dusenador.jpg') no-repeat fixed 50% 0;
			background-size: cover;
		}
	}

</style>