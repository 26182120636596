<template>
    <transition name="fade">
        <div class="spinner" v-show="isActive">
            <img src="/assets/images/Isologotipopng.png" alt="">
        </div>
    </transition>
</template>

<script>
    export default {
        name: "spinner",
        props: {
            isActive: Boolean
        },
    }
</script>

<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 400ms ease-in-out;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    .spinner {
        position: fixed;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;

         width: 100%;
         height: 100%;
         background-color: rgba(0, 0, 0, .7);
         z-index: 5000;

        & > img {
            opacity: .3;
            width: 75%;
            max-width: 500px;
            animation: pulse 1s infinite ease-out;
        }
    }

    @keyframes pulse {
        0%, 100%{
            opacity: .3;

        }

        50% {
            opacity: .5;
        }
    }
</style>